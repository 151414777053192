import React, { ButtonHTMLAttributes, ReactNode } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
}
const Button = (props: Props) => {
  return (
    <button
      {...props}
      className={`flex h-12 items-center justify-center rounded-full bg-primary px-8 pb-[2px] text-base leading-tight text-white hover:bg-gray focus:outline focus:outline-2 focus:outline-cyan disabled:bg-gray-300 disabled:text-gray ${
        props.className || ''
      }`}
    >
      {props.children}
    </button>
  );
};

export default Button;
